import React from 'react';
import { List, Datagrid, TextField, ReferenceInput, AutocompleteInput, TextInput, BooleanInput, BooleanField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const roles = [
    { name: "ROLE_MANAGER", cname: "manager", value: ["ROLE_MANAGER"] },
    { name: "ROLE_WORKER", cname: 'trabajador', value: ["ROLE_WORKER"] },
];

export const UsersList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="email" />
            <FieldGuesser source="name" />

            {/* While deprecated fields are hidden by default, using an explicit FieldGuesser component allows to add them back. */}
            <FieldGuesser source="roles" />
            <FieldGuesser source="company" />
            <BooleanField source="enabled" />
        </ListGuesser>
    </div>

);

export const UsersCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="email" />
        <InputGuesser source="name" />
        <InputGuesser source="lastName" />
        <InputGuesser source="username" />
        <BooleanInput source="enabled" />
        <TextInput
            source="password"
            type="password"
        />
        <AutocompleteInput
            source="roles"
            choices={roles}
            optionText="cname"
            optionValue="value"
        />

        <ReferenceInput
            source="company"
            reference="companies"
            label="Company"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

export const UserEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="email" />
        <InputGuesser source="name" />
        <InputGuesser source="lastName" />
        <InputGuesser source="username" />
        <InputGuesser source="phone" />
        <BooleanInput source="enabled" />
        <FieldGuesser source="roles" />
        <AutocompleteInput
            source="roles"
            choices={roles}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="company"
            reference="companies"
            label="Company"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);

import React from 'react';
import { List, Datagrid, TextField, ReferenceInput, AutocompleteInput, FunctionField, ChipField, ArrayInput, SimpleFormIterator, DateInput, TextInput } from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

import { ColorField, ColorInput } from 'react-admin-color-input';

export const ProjectsCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <ReferenceInput
      source="company"
      reference="companies"
      label="Company"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText={record => `${record.name} (${record.id})`} />
    </ReferenceInput>
    <InputGuesser source={"billable"} />
    <ColorInput source={"color"} />
    <ReferenceInput
      source="client"
      reference="clients"
      label="Client"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText={record => `${record.name} (${record.company})`}/>
    </ReferenceInput>
  </CreateGuesser>
);


import React from 'react';
import { List, Datagrid, TextField, ReferenceInput, AutocompleteInput, FunctionField, ChipField, ArrayInput, SimpleFormIterator, DateInput, TextInput } from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const ClientsCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} />
    <InputGuesser source={"address"} />
    <ReferenceInput
      source="company"
      reference="companies"
      label="Company"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

  </CreateGuesser>
);


import React from 'react';
import { DateTimeInput , DateField, FunctionField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    ShowGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const TimeTrackingEdit = props => (
    <EditGuesser {...props}>
        <DateField source={"start"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true}  />
        <DateField source={"finish"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <FieldGuesser source={"user"} addLabel={true} />
        <FieldGuesser source={"company"} addLabel={true} />
        <FieldGuesser source={"checkInLatitude"} addLabel={true} />
        <FieldGuesser source={"checkInLongitude"} addLabel={true} />
        <FieldGuesser source={"checkOutLatitude"} addLabel={true} />
        <FieldGuesser source={"checkOutLongitude"} addLabel={true} />
        <DateTimeInput source={"startModified"} />
        <DateTimeInput source={"finishModified"} />
        <DateField source={"startModifiedTime"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <DateField source={"finishModifiedTime"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <FieldGuesser source={"approvedBy"} addLabel={true} />
    </EditGuesser>
);



export const TimeTrackingList = props => (
    <ListGuesser {...props}>
        <DateField source={"start"} label={"Day"} options={{ year:"numeric", month: 'narrow', day: 'numeric' }}/>
        <DateField source={"start"} options={{ hour: '2-digit', minute: '2-digit' }} showTime/>
        <DateField source={"finish"} options={{ hour: '2-digit', minute: '2-digit' }} showTime/>
        <FieldGuesser source={"user"} />
        <FieldGuesser source={"company"} />
        <FunctionField label="GPS IN" render={record => `${record.checkInLatitude} ${record.checkInLongitude}`}/>
            <FieldGuesser source={"checkInAddress"} />
        <FunctionField label="GPS OUT" render={record => `${record.checkOutLatitude} ${record.checkOutLongitude}`}/>
            <FieldGuesser source={"checkOutAddress"} />
            <DateField source={"startModified"} options={{ hour: '2-digit', minute: '2-digit' }} showTime/>
        <DateField source={"finishModified"} options={{ hour: '2-digit', minute: '2-digit' }} showTime/>
        <FieldGuesser source={"approvedBy"} />
    </ListGuesser>
);

export const TimeTrackingShow = props => (
    <ShowGuesser {...props}>
        <DateField source={"start"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <DateField source={"finish"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <FieldGuesser source={"user"} addLabel={true} />
        <FieldGuesser source={"company"} addLabel={true} />
        <FieldGuesser source={"checkInLatitude"} addLabel={true} />
        <FieldGuesser source={"checkInLongitude"} addLabel={true} />
        <FieldGuesser source={"checkOutLatitude"} addLabel={true} />
        <FieldGuesser source={"checkOutLongitude"} addLabel={true} />
        <DateField source={"startModified"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <DateField source={"finishModified"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <DateField source={"startModifiedTime"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <DateField source={"finishModifiedTime"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <FieldGuesser source={"approvedBy"} addLabel={true} />
        <FieldGuesser source={"timeTrackingHistories"} addLabel={true} />
    </ShowGuesser>
);


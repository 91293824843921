import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {
    dataProvider as baseDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser  ,
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
} from '@api-platform/admin';
import authProvider from './authProvider';
import { Redirect } from 'react-router-dom';

import { Admin, Resource } from 'react-admin';

import Dashboard from './Dashboard';

import {UsersList, UsersCreate, UserEdit } from './Users';

import {TimeTrackingShow, TimeTrackingEdit, TimeTrackingList } from './TimeTracking';

import {ProjectsCreate} from "./Projects";
import {CompaniesList, CompaniesCreate, CompanyEdit} from "./Companies";
import {ClientsCreate} from "./Clients";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser);


export default props => (
    <HydraAdmin
        apiDocumentationParser={ apiDocumentationParser }
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        dashboard={Dashboard}
    >
        <ResourceGuesser name="users" list={UsersList} create={UsersCreate} edit={UserEdit}/>
        <ResourceGuesser name="companies" list={CompaniesList} create={CompaniesCreate} edit={CompanyEdit}/>
        <ResourceGuesser name="time_trackings" list={TimeTrackingList} edit={TimeTrackingEdit} show={TimeTrackingShow}/>
        <ResourceGuesser name="projects" create={ProjectsCreate}/>
        <ResourceGuesser name="clients" create={ClientsCreate}/>
        <ResourceGuesser name="user_projects"/>
        <ResourceGuesser name="add_ons"/>
    </HydraAdmin>

);

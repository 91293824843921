import React from 'react';
import { List, Datagrid, TextField, ReferenceInput, AutocompleteInput, FunctionField, ChipField, ArrayInput, SimpleFormIterator, DateInput, TextInput } from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const CompaniesList = props => {
    return (
        <ListGuesser {...props}>
            <FieldGuesser source={"name"} />
            <FieldGuesser source={"address"} />
            <FieldGuesser source={"users"} />
            <FunctionField label="enabled / archived" render={record => `${record.totalEnabledUsers} / ${record.totalArchivedUsers}`}/>
            <FieldGuesser source={"manager"} />
            <FieldGuesser source={"coords"} />

        </ListGuesser>
    );
}


export const CompaniesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"} />
        <InputGuesser source={"address"} />
        <ArrayInput source="coords">
            <SimpleFormIterator disableRemove >
                <TextInput source="latitude" />
                <TextInput source="longitude" />
            </SimpleFormIterator>
        </ArrayInput>
    </CreateGuesser>
);

export const CompanyEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"name"} />
        <InputGuesser source={"address"} />
        <InputGuesser source={"users"} />
        <InputGuesser source={"manager"} />
        <ArrayInput source="coords">
            <SimpleFormIterator disableRemove >
                <TextInput source="latitude" />
                <TextInput source="longitude" />
            </SimpleFormIterator>
        </ArrayInput>
    </EditGuesser>
);
